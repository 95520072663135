/* eslint-disable jsx-a11y/anchor-is-valid */
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// import Cookies from 'universal-cookie'
// import { GoogleLogin } from 'react-google-login';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import {
  isMobile
} from "react-device-detect";
import axios from 'axios'
import './custom.scss'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, Modal, ModalHeader, ModalBody
} from "reactstrap";
const crypto = require('crypto')
const queryString = require('query-string');
const PATHLOGIN = process.env.REACT_APP_BACK_END_PATH || 'https://givvy-backend-site.herokuapp.com'




function LoginSupport() {
  // console.log(`login`)
  const clientId = '946443114197-b65unujbpc18jsk33nlk65apjqoo34jq.apps.googleusercontent.com'
  const [values, setValues] = useState({ username: null, password: null, error: false, success: false });
  const [isClickedLoginButton, setIsClickedLoginButton] = useState(false)
  const [valuesFacebookOrGoogle, setValuesFacebookOrGoogle] = useState({});
  const [isClicked, setIsClicked] = useState(false);
  // const history = useHistory();
  // const cookies = new Cookies()
  const cryptResponse = (object, key) => {
    const cipher = crypto.createCipher('aes-128-ecb', key || 'daumirathakerite')
    const text = JSON.stringify(object)
    var crypted = cipher.update(text, 'utf-8', 'hex')
    crypted += cipher.final('hex')

    return crypted
  }
  const decryptResponse = (cookies) => {
    const decipher = crypto.createDecipher('aes-128-ecb', 'daumirathakerite')
    var dec = decipher.update(cookies, 'hex', 'utf8')
    dec += decipher.final('utf8')
    const res = JSON.parse(dec)
    return res
  }
  React.useEffect(() => {

    // document.cookie = "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    // if (localStorage.getItem('nigol')) {
    //   //console.log(localStorage.getItem('nigol'))
    //   let res = decryptResponse(localStorage.getItem('nigol'))
    //   if (res) {
    //     window.location.pathname = '/support/contact'
    //   }
    // }
  });
  // React.useEffect(() => {
  //   const parsed = queryString.parse(window.location.search);
  //   console.log(parsed)
  //   if (parsed.appName) {
  //     if (localStorage.getItem('appInfo')) {
  //       // setAppInfo(JSON.parse(localStorage.getItem('appInfo')))
  //     } else {
  //       // setAppInfo(parsed)
  //       localStorage.setItem('appInfo', JSON.stringify(parsed))
  //     }
  //   }
  // }, [])

  const responseFacebook = (response) => {
    signInFacebookOrGoogle(response)
  }
  const responseGoogle = (response) => {
    signInFacebookOrGoogle(response)

  }
  async function loginFacebookOrGoogle(user) {
    return new Promise((resolve, reject) => {
      //console.log(user)
      let data = {
        externalId: user.sub,
        externalValidationToken: user.accessToken,
        email: user.email,
        name: user.name,
        isGoogleLogin: true,
        isFromSite: true
      }
      const cryptRes = data

      axios.post(`${PATHLOGIN}/uuliControlPanel`, data, {
        headers: {
          packagename: 'com.givvy'
        }
      }).then((res) => {
        //console.log(res)
        if (res.data.statusCode === 200) {
          let user = res.data.result.user
          // let config = res.data.result.config
          let myObject = {
            id: user.id || user._id,
            credits: user.credits,
            email: user.email,
            photo: user.photo,
            name: user.name || user.username || user.email,
            currency: user.currency,
            userBalance: user.userBalance,
            // paypalMinCashout: config.paypalMinCashout,
            // coinsForCentExchangeRate: config.coinsForCentExchangeRate,
          }
          let response = cryptResponse(myObject)
          resolve({ error: false, success: true, result: response })
        } else {
          resolve({ error: res.data.statusText, success: false })
        }
      })
    })
  }
  async function signInFacebookOrGoogle(response) {
    response = jwtDecode(response.credential); // Decode JWT token
    response.audience = clientId
    console.log(response)
    const isLoggedIn = await loginFacebookOrGoogle(response)
    console.log(isLoggedIn)
    //console.log(isLoggedIn)
    if (!isLoggedIn.error) {
      localStorage.setItem('nigol', isLoggedIn.result);
      window.location.pathname = '/support/contact'
    } else {
      setValuesFacebookOrGoogle({ ...values, error: isLoggedIn.payload.error, success: false })
    }
  }
  const responseGoogleFailure = (e) => {
    console.log(`failure google`)
    console.log(e)
  }
  function usernameOnChange(e) {
    setValues({ ...values, username: e.target.value })
  }
  function passwordOnChange(e) {
    setValues({ ...values, password: e.target.value })
  }
  const responseMessage = (event) => {

  }
  const copyClip = () => {
    const copy = require('clipboard-copy')
    copy(window.location.href)
  }


  //https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png
  //https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png
  if (isMobile) {
    window.location.url = 'https://givvy.net/support/contact'
    return (null)
    // return (
    //   <>
    //     {document.body.style.setProperty('background-color', 'white')}
    //     <div className="container-login-mobile">
    //       <Modal isOpen={isClicked} toggle={() => { setIsClicked(false) }} size="sm" contentClassName={"login-problem-modal-content"} className={"login-problem-modal"}>
    //         <ModalBody>
    //           <img className="img-modal-login" src='https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1707925134423.png' />
    //           <div className="d-flex justify-content-center">
    //             <p style={{ color: '#170F49', fontSize: '20px' }}>Having trouble logging in?</p>
    //           </div>
    //           <div className="d-flex justify-content-center">
    //             <p className="text-modal-loing">Please copy the website URL and paste it</p>
    //           </div>
    //           <div className="d-flex justify-content-center">
    //             <p className="text-modal-loing">your <b>Google Chrome browser.</b></p>
    //           </div>
    //           <div className="d-flex justify-content-center mt-3">
    //             <button onClick={() => { setIsClicked(false) }} className="btn btn-custom-modal-problem mx-1">Cancel</button>
    //             <button onClick={() => { copyClip(); setIsClicked(false) }} className="btn btn-custom-modal-problem2 mx-1">Copy link</button>
    //           </div>
    //         </ModalBody>
    //       </Modal>
    //       <div className="mx-4 py-4 pt-2" style={{ color: 'white' }}>
    //         <p style={{ fontSize: '38px', fontWeight: 'bold' }} className=" m-0 d-flex justify-content-start">Welcome!</p>
    //         <p style={{ fontWeight: 'bold' }} className="mb-1 d-flex justify-content-start">This is Givvy Customer Support Panel</p>
    //         <div className="mt-2 d-flex justify-content-center"><p style={{ fontSize: '12px', color: '#FFFFFF99', }}>Let us know how we can assist you. In order to continue you must sign up with your Google account.</p></div>
    //         {/* {isClickedLoginButton ? <GoogleOneTapLogin onError={(error) => { console.log(error) }} onSuccess={(response) => { signInFacebookOrGoogle(response) }} googleAccountConfigs={{
    //           client_id: '264144162687-k6dt6jlo5arfuprknnme7ssnv70l3u7l.apps.googleusercontent.com'
    //         }} /> : null} */}
    //         <GoogleOAuthProvider clientId="264144162687-k6dt6jlo5arfuprknnme7ssnv70l3u7l.apps.googleusercontent.com">
    //           <div className="App">
    //             <h2 className="white">Sign up with Google</h2>
    //             <GoogleLogin
    //               onSuccess={(response) => { signInFacebookOrGoogle(response) }}
    //               onError={(error) => { console.log(error) }}
    //               scope="email profile"
    //               render={renderProps => (
    //                 <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
    //                   Custom Google Login Button
    //                 </button>
    //               )}
    //             />
    //           </div>
    //         </GoogleOAuthProvider>

    //         {/* <div className="mt-5">
    //           <div onClick={() => { document.cookie = "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; setIsClickedLoginButton(true); }} id="text-welcome-button-login" className="px-2 py-2">
    //             <img className="mx-2" style={{ width: '25px', height: '25px', verticalAlign: 'middle' }} src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704213071713.png" />
    //             <span id="text-welcome-button-login-txt">Sign up with Google</span>
    //           </div>
    //         </div> */}
    //         {/* <div onClick={() => { setIsClicked(true) }} className="d-flex justify-content-start mt-3"><div><span style={{ width: '290px', fontSize: '12px', color: 'white', opacity: '0.8' }}>Trouble logging in? </span><span className="bold-text-login" style={{ cursor: 'pointer' }}>Click here.</span></div></div> */}

    //         {/* <Modal isOpen={isClicked} toggle={() => { setIsClicked(false) }} size="sm" className={"login-problem-modal"}>
    //           <ModalBody>
    //             <img style={{ width: '100%', height: "100%" }} src='https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1707925134423.png' />
    //           </ModalBody>
    //         </Modal> */}
    //       </div>
    //       <img id="image-login-people" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png" />

    //     </div>


    //   </>
    // )
  } else {
    window.location.url = 'https://givvy.net/support/contact'
    return (null)
    // return (
    //   <>
    //     {document.body.style.setProperty('background-color', 'white')}
    //     <div className="container-login-support">
    //       <div className="d-flex justify-content-between">
    //         <div className="m-8">
    //           <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
    //           <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
    //           <div id="text-welcome-2" className="d-flex justify-content-start mt-5">Let us know how we can assist you. In order to continue you must sign up with your Google account.</div>
    //           {/* <GoogleOneTapLogin onError={(error) => { console.log(error) }} onSuccess={(response) => { signInFacebookOrGoogle(response) }} googleAccountConfigs={{
    //             client_id: '264144162687-k6dt6jlo5arfuprknnme7ssnv70l3u7l.apps.googleusercontent.com'
    //           }} /> */}
    //           <GoogleOAuthProvider clientId="264144162687-k6dt6jlo5arfuprknnme7ssnv70l3u7l.apps.googleusercontent.com">
    //             <div className="App">
    //               <h2 className="white">Sign up with Google</h2>
    //               <GoogleLogin
    //                 onSuccess={(response) => { signInFacebookOrGoogle(response) }}
    //                 onError={(error) => { console.log(error) }}
    //                 scope="email profile"
    //                 render={renderProps => (
    //                   <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
    //                     Custom Google Login Button
    //                   </button>
    //                 )}
    //               />
    //             </div>
    //           </GoogleOAuthProvider>
    //         </div>

    //         <div className="mt-4">
    //           <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
    //         </div>
    //       </div>
    //       {/* <div className="mt-5">
    //         <div onClick={() => { setIsClickedLoginButton(true); document.cookie = "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" }} id="text-welcome-button-login-web" className="px-2 py-2">
    //           <img className="mx-2" style={{ width: '25px', height: '25px', verticalAlign: 'middle' }} src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704213071713.png" />
    //           <span id="text-welcome-button-login-txt">Sign up with Google</span>
    //         </div>
    //       </div> */}
    //       {/* <div className="d-flex justify-content-start mt-2"><div><span style={{ width: '290px', fontSize: '14px', color: 'white', opacity: '0.8' }}>Trouble logging in? </span><span className="bold-text-login" style={{ cursor: 'pointer' }}>Click here.</span></div></div> */}
    //     </div>
    //     {/* <img id="main-login-image" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png"></img>
    //     <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
    //     <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
    //     <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
    //     <div id="text-welcome-2" className="d-flex justify-content-start">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div> */}
    //   </>
    // );
  }


}

export default LoginSupport

