import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Chatbot from '../ChatBot/ChatBotIndex';
import './newCss.css'; // Asegúrate de crear este archivo para los estilos
import NewModuleContactForm from './NewModuleContactForm';


// const data = require('./exampleJson.json')
// Main Chatbot component
const NewContactForm = () => {
  const [isStartedChat, setIsStartedChat] = useState(false)
  const [isOpenOldQueries, setIsOpenOldQueries] = useState(false)

  if (isStartedChat) {
    return (<Chatbot hideChat={() => {setIsStartedChat(false)}}></Chatbot>)
  } else if(isOpenOldQueries){
    return(<NewModuleContactForm hideQueries={() => {setIsOpenOldQueries(false)}}></NewModuleContactForm>)
  } else {
    return (
      <div className="app-container">
        <div className="greeting">
          <h1>Welcome back, User!</h1>
        </div>
        <div className="button-container">
          <button onClick={() => { setIsStartedChat(true) }} className="btn start-chat">Start Chat</button>
          <button onClick={() => { setIsOpenOldQueries(true) }} className="btn old-queries">Old Queries</button>
        </div>
      </div>
    );
  }

}

export default NewContactForm;