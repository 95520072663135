// Import React and useState
import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const queryString = require('query-string');


// const data = require('./exampleJson.json')
// Main Chatbot component
const Chatbot = ({ hideChat }) => {
  // State to manage chatbot messages and user input
  const [messages, setMessages] = useState([]);
  const [usernameQuery, setUsernameQuery] = useState(null)
  const [appNameQuery, setAppNameQuery] = useState('')
  const [stepSelected, setStepSelected] = useState(0)
  const [selectClass, setSelectClass] = useState('')
  const [step, setStep] = useState(0); // Tracks the current conversation step
  const [userInputDesc, setUserInputDesc] = useState(""); // Tracks user's text input
  const [userInputEmail, setUserInputEmail] = useState(""); // Tracks user's text input
  const [inputDisabled, setInputDisabled] = useState(true); // Tracks user's text input
  const [data, setData] = useState({}); // Tracks user's text input
  const [isLoading, setIsloading] = useState(true); // Tracks user's text input
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [appNames, setAppNames] = useState([])
  const [userBrowser, setUserBrowser] = useState("")
  const [topic, setTopic] = useState('')
  const [inputSelected, setInputSelected] = useState(true)
  const [chat, setChat] = useState(false)
  const [placeholder, setPlaceholder] = useState('Please describe your issue briefly:')

  const chatEndRef = useRef(null); // Ref for scrolling to the bottom

  // Random greetings array
  const greetings = [
    "Hello! How can I assist you today?",
    "Hi there! What can I help you with?",
    "Greetings! How may I assist you?",
    "Hey! Need help with something?",
    "Hi! How can I support you today?"
  ];
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (5 MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        alert('The file is too large. Maximum allowed size is 5 MB.');
        return;
      }

      setErrorMessage(null); // Clear any previous error messages

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setMessages((prev) => [
          ...prev,
          { sender: "user", image: reader.result }
        ]);
      };
      reader.readAsDataURL(file);
      console.log(reader.result)

    }
  };
  const getAppNames = () => {
    console.log(`appNames`)
    Axios.get('https://givvy-general-payment.herokuapp.com/getAllAppsSchemaForAdminPanel').then(res => {
      setAppNames(res.data.appObjects1)
      // if (!isGettedApps) {
      // }
    })
  }
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  // Display random greeting on component mount
  useEffect(() => {
    getAppNames()
    getDataFromServer()
    const initialGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    const parsed = queryString.parse(window.location.search);
    console.log(parsed)
    const fpPromise = FingerprintJS.load();
    fpPromise.then(fp => {
      fp.get().then(result => {
        setUserBrowser(result.visitorId)
        console.log(result.visitorId); // This is the pseudo-unique browser identifier
        // getQueriesByUser({ id: result.visitorId })
      });
    });
    if (parsed.appName) {
      setAppNameQuery(parsed.appName)
      setUsernameQuery(parsed.username)
      setMessages([{ sender: "bot", text: initialGreeting }]);
    } else {
      setMessages([{ sender: "bot", text: initialGreeting }, { sender: "bot", text: "Please select an app and enter your username:" }]);
    }
  }, isLoading);

  // Scroll to the bottom of the chat when messages change
  useEffect(() => {
    let currChat = document.getElementById('chat-cont')
    if (currChat) {
      currChat.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, chat]);

  const getDataFromServer = () => {
    Axios.post(`https://givvy-backend-site.herokuapp.com/getDataForChatBot`, {}).then((res) => {
      console.log(res)
      setData(res.data.result)
      setIsloading(false)
    })
  }
  const sendDataToServer = () => {
    console.log(`answer: ${stepSelected} -> response from user: ${userInputDesc}`)
    Axios.post('https://givvy-backend-site.herokuapp.com/saveContactFormUser', {
      images: [selectedImage],
      userId: userBrowser,
      appName: appNames.find(f => f.realName == appNameQuery).appName,
      appRealName: appNameQuery,
      topic: topic,
      username: usernameQuery,
      explanation: `answer: ${stepSelected} -> response from user: ${userInputDesc}`,
      email: userInputEmail
    })
    document.getElementById('input-custom').value = ""
    setSelectedImage(null)
  }
  // Handle bot responses based on button click and step
  const handleResponse = (input) => {
    const stepIndex = parseInt(step) - 1;
    let botResponse = ""
    console.log(input, step)
    if (input == "yes") {
      console.log(input)
      botResponse = greetings[Math.floor(Math.random() * greetings.length)];
      setMessages((prev) => [
        { sender: "bot", text: botResponse },
      ]);
      setStep(0)
    } else if (input == "no") {
      botResponse = "Thank you for reaching out! Have a great day.";
      setMessages((prev) => [
        ...prev,
        { sender: "user", text: "No" },
        { sender: "bot", text: botResponse },
      ]);
      setStep(null)
    } else if (step === 0) {
      // Selección de un tema principal
      setStep(parseInt(input));
      const issue = data.mainIssuesWithResponses[input - 1];
      setTopic(issue.mainIssueName)
      if (issue.mainIssueName == "Other General") {
        handleDescIssue(`${issue.mainIssueName}`, "Describe your problem with our apps and our team will look into it as soon as possible!")
        // setUserInput("");
        setStep(101)
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "user", text: issue.mainIssueName },
          { sender: "bot", text: "Please choose an option:" },
        ]);
        setStep(parseInt(input));
      }

    } else if (step >= 1) {
      if (input != 'submit') {
        const issue = data.mainIssuesWithResponses[stepIndex];
        const selectedAnswer = issue.answers[parseInt(input) - 1];
        if (selectedAnswer.needToPopulate) {
          if (selectedAnswer.answer != "") {
            setMessages((prev) => [
              ...prev,
              { sender: "user", text: selectedAnswer.option },
              { sender: "bot", text: selectedAnswer.answer },
            ]);
            handleDescIssue(null, selectedAnswer.placeholder)
          } else {
            handleDescIssue(`${issue.mainIssueName} - ${selectedAnswer.option}`, selectedAnswer.placeholder)
          }
          setStep(101)
          return;
        } else if (step == 101) {
          handleEmail(userInputDesc)
        } else {
          setMessages((prev) => [
            ...prev,
            { sender: "user", text: selectedAnswer.option },
            { sender: "bot", text: selectedAnswer.answer },
          ]);
          if (selectedAnswer.needToPopulate) {
            handleEmail(`${issue.mainIssueName}`)
            // setUserInput("");
            setStep(102)
          } else {
            handleMoreHelp()
          }
          return
        }
      } else {
        if (step == 101) {
          handleEmail(userInputDesc)
          setStep(102)
        } else if (step == 102) {
          handleThanks(userInputEmail)
          handleMoreHelp()
          return
        }
      }
    }
  };

  const handleMoreHelp = () => {
    setInputDisabled(true)
    setStep(null)
    setTimeout(() => {
      let botResponse = ""
      botResponse = "Do you have any other questions or issues? Please respond with Yes or No.";
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: botResponse },
      ]);
      setStep(103)
      return
    }, 1500)
  }
  const handleThanks = (text) => {
    setInputSelected(true)
    setInputDisabled(true)
    setMessages((prev) => [
      ...prev,
      { sender: "user", text: text }
    ]);
    sendDataToServer()
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: `Thank you for your time! We will analyze your question and respond shortly.` },
      ]);
      console.log(userInputDesc, userInputEmail)
      return
    }, 500)
  }
  const handleEmail = (text) => {
    setInputSelected(false)
    setInputDisabled(false)
    setMessages((prev) => [
      ...prev,
      { sender: "user", text: text }
    ]);
    document.getElementById('input-custom').value = ""
    document.getElementById('input-custom').placeholder = "Type your Email..."

    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: "Thank you! Could you please provide your email so we can send you a solution?" },
      ]);
      return
    }, 500)
  }
  const handleDescIssue = (text, currPlaceholder) => {
    setStepSelected(currPlaceholder)
    setInputDisabled(false)
    if (text) {
      setMessages((prev) => [
        ...prev,
        { sender: "user", text: text }
      ]);
    }
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: currPlaceholder ? currPlaceholder : placeholder }
      ]);
      return
    }, 500)
  }
  const renderAppNames = () => {
    return appNames.map((value) => {

      return (<option>{value.realName}</option>)
    })
  }
  const renderAppNameGlobal = () => {

    return <>
      <div className="d-flex justify-content-center">
        <select onChange={(e) => {
          setAppNameQuery(e.target.value);
          setSelectClass('our-app-givvy')
        }} className={`custom-select custom-select-lg mb-3 ${selectClass}`}>
          <option selected>Choose an app</option>
          {renderAppNames()}
        </select>
      </div>
    </>
  }
  const renderOptions = (step) => {
    if (!data || !data.mainIssuesWithResponses) {
      console.error("Data or mainIssuesWithResponses is undefined");
      return <p>Loading...</p>; // Mensaje de carga
    }

    if (step === 0) {
      // Renderizar opciones principales (Withdraw Issues, Earning Limitations, etc.)
      return (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
          justifyContent: 'center', // Centra las opciones horizontalmente
          alignItems: 'center', // Centra las opciones verticalmente
          gap: '10px', // Espacio entre los botones
          marginTop: '20px',
          padding: '10px',
        }}>
          {data.mainIssuesWithResponses.map((issue, index) => (
            <button
              key={index}
              onClick={() => handleResponse(index + 1)}
              style={{
                backgroundColor: '#ffffff', // Fondo blanco
                color: '#4a148c', // Texto morado
                border: '2px solid #4a148c', // Borde morado
                borderRadius: '20px', // Bordes redondeados
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              {issue.mainIssueName}
            </button>
          ))}
        </div>
      );
    } else if (step >= 1) {
      // Renderizar sub-opciones basadas en la selección del usuario
      if (step === 101) {
        // Renderizar textarea para capturar el problema del usuario
        return (null);
      } else if (step === 103) {

        // Renderizar opciones Yes/No
        setTimeout(() => { { setChat(true) } }, 200)
        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
            justifyContent: 'center', // Centra las opciones horizontalmente
            alignItems: 'center', // Centra las opciones verticalmente
            gap: '10px', // Espacio entre los botones
            marginTop: '20px',
            padding: '10px',
          }}>
            <button
              key={1}
              onClick={() => handleResponse("yes")}
              style={{
                backgroundColor: '#ffffff', // Fondo blanco
                color: '#4a148c', // Texto morado
                border: '2px solid #4a148c', // Borde morado
                borderRadius: '20px', // Bordes redondeados
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              Yes
            </button>
            <button
              key={2}
              onClick={() => handleResponse("no")}
              style={{
                backgroundColor: '#ffffff', // Fondo blanco
                color: '#4a148c', // Texto morado
                border: '2px solid #4a148c', // Borde morado
                borderRadius: '20px', // Bordes redondeados
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              No
            </button>
          </div>
        );
      } else if (step === 104 || step == 102) {
        // Renderizar formulario para capturar el correo electrónico del usuario
        return (null);
      } else {
        const issue = data.mainIssuesWithResponses[step - 1];
        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
            justifyContent: 'center', // Centra las opciones horizontalmente
            alignItems: 'center', // Centra las opciones verticalmente
            gap: '10px', // Espacio entre los botones
            marginTop: '20px',
            padding: '10px',
          }}>
            {issue.answers.map((answer, index) => (
              <button
                key={index}
                onClick={() => handleResponse(index + 1)}
                style={{
                  backgroundColor: '#ffffff', // Fondo blanco
                  color: '#4a148c', // Texto morado
                  border: '2px solid #4a148c', // Borde morado
                  borderRadius: '20px', // Bordes redondeados
                  padding: '10px 20px', // Tamaño del botón
                  fontSize: '14px',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
              >
                {answer.option}
              </button>
            ))}
          </div>
        );
      }

    }

    // Fallback en caso de error
    return null;
  };

  if (!isLoading) {
    return (
      <div
        style={{
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: '#1e1e2f',
        }}
      >
        <button
          onClick={() => { hideChat() }}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            backgroundColor: '#f44336',
            color: '#fff',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ×
        </button>
        {/* Chat Container */}
        <div
          style={{
            flex: 1,
            width: '100%',
            maxWidth: '600px',
            overflowY: 'auto',
            padding: '15px',
            backgroundColor: '#2b2b3c',
            borderRadius: '15px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            scrollbarWidth: 'none', // Ocultar scrollbar en Firefox

          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                textAlign: msg.sender === 'bot' ? 'left' : 'right',
                margin: '15px 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {msg.sender === 'bot' && (
                <img
                  src="https://static.vecteezy.com/system/resources/previews/019/495/327/non_2x/cute-woman-girl-avatar-user-person-people-short-hair-semi-solid-black-and-white-vector.jpg"
                  alt="Bot Avatar"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginRight: '10px',
                    border: '2px solid #6a1b9a',
                  }}
                />
              )}
              <div
                style={{
                  backgroundColor: msg.sender === 'bot' ? '#6a1b9a' : '#4caf50',
                  color: '#fff',
                  borderRadius: '20px',
                  padding: '12px 18px',
                  fontSize: '15px',
                  maxWidth: 'calc(100% - 80px)',
                  wordWrap: 'break-word',
                  display: 'inline-block',
                }}
              >
                {msg.text}
                {msg.image && (
                  <img
                    src={msg.image}
                    alt="Preview"
                    style={{
                      marginTop: '10px',
                      maxWidth: '100px',
                      maxHeight: '100px',
                      borderRadius: '10px',
                    }}
                  />
                )}
              </div>
              {msg.sender === 'user' && (
                <img
                  src="https://media.istockphoto.com/id/1326784239/es/vector/avatar-de-perfil-neutro-de-g%C3%A9nero-vista-frontal-de-la-cara-de-una-persona-an%C3%B3nima.jpg?s=612x612&w=0&k=20&c=buAEGN6oUzuHI5oFZLRA66b-onN1CQebrIHhND15_rI="
                  alt="User Avatar"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginLeft: '10px',
                    border: '2px solid #4caf50',
                  }}
                />
              )}
            </div>
          ))}
          {!appNameQuery || !usernameQuery ? renderAppNameGlobal() : <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
                marginTop: '20px',
              }}
            >
              {renderOptions(step)}
            </div>
            <div id={"chat-cont"} />
          </>}



          {/* Options */}

        </div>

        {/* Input Container */}
        {!inputDisabled || !usernameQuery ? <div style={{
          width: '100%',
          maxWidth: '600px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#2b2b3c',
          padding: '10px 15px',
          borderRadius: '25px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
          marginTop: '20px',
        }}
        >
          <div
            style={{
              width: '100%',
              maxWidth: '600px',
              display: 'flex',
              alignItems: 'center',
              padding: '10px 15px',
              borderRadius: '25px',
              marginTop: '20px',
            }}

          >
            {/* File Upload Icon */}
            <label
              htmlFor="file-upload"
              style={{
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              <img
                src="https://png.pngtree.com/png-clipart/20190921/original/pngtree-file-upload-icon-png-image_4717174.jpg"
                alt="Upload"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#fff',
                  padding: '5px',
                  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
                }}
              />
            </label>
            <input
              disabled={inputDisabled}
              type="file"
              id="file-upload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />

            {/* Image Preview with Remove Option */}
            {selectedImage && (
              <div style={{ position: 'relative', marginRight: '10px' }}>
                <img
                  src={selectedImage}
                  alt="Selected Preview"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '5px',
                  }}
                />
                <button
                  onClick={handleRemoveImage}
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    backgroundColor: '#f44336',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  ×
                </button>
              </div>
            )}
            {/* Error Message */}

            {/* Textarea Input */}
            {inputSelected || !usernameQuery ?
              <textarea
                onChange={(e) => {
                  if (!usernameQuery) {

                  } else {
                    setUserInputDesc(e.target.value);
                  }

                }}
                id="input-custom"
                placeholder={!usernameQuery ? "Username..." : "Type your message here..."}
                style={{
                  flex: 1,
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  outline: 'none',
                  resize: 'none',
                }}
              /> : <textarea
                onChange={(e) => {
                  setUserInputEmail(e.target.value);
                }}
                id="input-custom"
                placeholder="Type your email here..."
                style={{
                  flex: 1,
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  outline: 'none',
                  resize: 'none',
                }}
              />}

            {/* Send Button */}
            <button
              onClick={() => {
                if (usernameQuery == null || usernameQuery == "") {
                  let curr = document.getElementById('input-custom')
                  if (curr.value == "" || curr.value == null) {
                    alert("Not valid username")
                  } else {
                    setUsernameQuery(curr.value)
                    setMessages((prev) => [
                      ...prev,
                      { sender: "user", text: `App Name: ${appNameQuery}` },
                      { sender: "user", text: `Username: ${curr.value}` },
                      { sender: "bot", text: "Please choose an option:" }
                    ]);
                  }
                } else {
                  if (step == 102) {
                    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userInputEmail)) {
                      handleResponse('submit')
                    } else {
                      alert('Not valid email')
                    }
                  } else if(step == 101){
                    if (userInputDesc != "") {
                      handleResponse('submit')
                    } else {
                      alert('Cannot be empty')
                    }
                  } else {
                    handleResponse('submit')
                  }
                }
              }
              }
              style={{
                backgroundColor: '#4caf50',
                color: '#fff',
                border: 'none',
                padding: '10px 15px',
                borderRadius: '20px',
                cursor: 'pointer',
                fontSize: '14px',
                marginLeft: '10px',
                transition: 'transform 0.2s ease-in-out',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}

            >
              {!usernameQuery ? "Save" : "Send"}
            </button>
          </div>
        </div> : null}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Chatbot;
